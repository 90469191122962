@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-display: swap;
  font-family: 'Apis';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Apis/apis-regular.woff2') format('woff2'),
    url('fonts/Apis/apis-regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Apis';
  font-style: italic;
  font-weight: 400;
  src: url('fonts/Apis/apis-italic.woff2') format('woff2'),
    url('fonts/Apis/apis-italic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Apis';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/Apis/apis-medium.woff2') format('woff2'),
    url('fonts/Apis/apis-medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Apis';
  font-style: italic;
  font-weight: 500;
  src: url('fonts/Apis/apis-mediumitalic.woff2') format('woff2'),
    url('fonts/Apis/apis-mediumitalic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Apis';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/Apis/apis-bold.woff2') format('woff2'),
    url('fonts/Apis/apis-bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Apis';
  font-style: italic;
  font-weight: 700;
  src: url('fonts/Apis/apis-bolditalic.woff2') format('woff2'),
    url('fonts/Apis/apis-bolditalic.woff') format('woff');
}

@font-face {
  font-family: 'Luckiest Guy';
  src: url('fonts/LuckiestGuy/LuckiestGuy-Regular.woff2') format('woff2'),
    url('fonts/LuckiestGuy/LuckiestGuy-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Apis';
}

.shake {
  animation: horizontal-shaking 0.3s ease-in 0s;
}

.fly {
  animation: fly 5s ease 0s infinite alternate;
}

.fly img {
  transform: scale(0);
  animation: showBubble 300ms ease-out forwards;
}

.fly:nth-child(2) img {
  animation-delay: 400ms;
}
.fly:nth-child(3) img {
  animation-delay: 600ms;
}
.fly:nth-child(5) img {
  animation-delay: 500ms;
}
.fly:nth-child(6) img {
  animation-delay: 300ms;
}

.fly:nth-child(2) {
  animation-duration: 4s;
}
.fly:nth-child(3) {
  animation-duration: 10s;
}
.fly:nth-child(5) {
  animation-duration: 8s;
}
.fly:nth-child(6) {
  animation-duration: 6s;
}

.rotate {
  animation: rotate 8s linear 0s infinite;
}

.rotate-rvrs {
  animation: rotate 8s linear 0s reverse forwards;
}

.path {
  stroke-width: 28;
  stroke: #5c7fff;
  fill: transparent;
  stroke-dasharray: 1600px;
  stroke-dashoffset: 1600px;
  transition-duration: 0s;
  transition-timing-function: linear;
}

.path.animate {
  animation: dash 8s linear alternate forwards;
}

.bg-path {
  stroke-width: 24;
  stroke: #000e42;
  fill: transparent;
}

.dot {
  animation: dotRotate 8s linear 0s forwards;
}

.shake {
  animation: horizontalShaking 300ms ease 0s alternate forwards;
}

@keyframes horizontalShaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes dotRotate {
  from {
    transform: translateX(-50%) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(360deg);
  }
}

@keyframes dash {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 1600px;
  }
}

@keyframes showBubble {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes fly {
  from {
    transform: translateY(30px);
  }
  to {
    transform: translateY(0px);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate2 {
  from {
    color: white;
    transform: rotate(360deg);
  }
  to {
    color: red;
    transform: rotate(0deg);
  }
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
    color: red;
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
    color: red;
  }
  100% {
    transform: translateX(0);
    color: black;
  }
}
